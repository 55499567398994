<template>
  <div class="signup-verification">
    <h1 class="signup-verification__title">Email Verification</h1>
    <div class="signup-verification__subtitle text">
      We have sent code to your email
      <span class="signup-verification__email">{{ $route.query.email }}</span>
    </div>
    <div class="signup-verification__code-box">
      <VerificationCodeField
        :error="isVerificationCodeError"
        :error-txt="verificationCodeErrorText"
        @focus="resetServerError('confirmation_code')"
        @change="onChange"
        @complete="onComplete"
      />
    </div>
    <div class="signup-verification__caption">Didn't receive the code?</div>
    <button
      class="signup-verification__resend-btn"
      :disabled="!!getAuthNotification"
      @click="resendCode"
    >
      Resend code
    </button>
    <div class="signup-verification__controls">
      <ButtonBase :loading="loading" @click="verifyEmailHandler"
        >Submit</ButtonBase
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import verifyCodeValidation from '@/mixins/validation/forms/verifyCode';
import VerificationCodeField from '@/elements/VerificationCodeField.vue';

export default {
  components: {
    VerificationCodeField,
  },
  mixins: [verifyCodeValidation],

  data() {
    return {
      verificationCode: '',
      loading: false,
    };
  },
  destroyed() {
    if (!!this.getAuthNotification)
      this.$store.commit('CLEAR_AUTH_NOTIFICATION');
  },
  computed: {
    ...mapGetters(['getAuthNotification']),
  },
  methods: {
    async verifyEmailHandler() {
      if (this.isInvalidForm) {
        this.showValidationErrors();
        return;
      }
      this.loading = true;
      await this.$store.dispatch('signupVerify', {
        email: this.$route.query.email,
        confirmation_code: this.verificationCode,
      });
      this.loading = false;
    },
    async resendCode() {
      this.hideValidationErrors();
      this.resetServerError('confirmation_code');

      await this.$store.dispatch('signupResendVerify', {
        email: this.$route.query.email,
      });
    },
    onChange(value) {
      this.verificationCode = value;
    },
    onComplete(value) {
      this.verificationCode = value;
    },
  },
};
</script>

<style lang="sass" scoped>
.signup-verification
  display: flex
  flex-direction: column
  justify-content: center
  text-align: center

.signup-verification__subtitle
  @include adaptive-font-size(16, 14, 12)
  @include adaptive-line-height(24, 24, 18)
  margin-top: 12px
  margin-bottom: 64px
  @include l
    margin-bottom: 44px
  @include ms
    margin-top: 8px
    margin-bottom: 40px

.signup-verification__caption,
.signup-verification__resend-btn
  @include adaptive-font-size(14, 14, 12)
  @include adaptive-line-height(21, 21, 18)

.signup-verification__caption
  color: $white

.signup-verification__resend-btn
  font-weight: 600
  color: $primary-color-blue
  margin-top: 4px
  @include ms
    margin-top: 0

.signup-verification__resend-btn:active
  color: $primary-color-blue-focus

.signup-verification__resend-btn[disabled]
  color: $primary-color-blue-disabled

.signup-verification__email
  color: $pink-color
  font-weight: 600
  @include ms
    display: block

.signup-verification__controls
  display: flex
  justify-content: center
  margin-top: 64px
  @include l
    margin-top: 44px
  @include ms
    margin-top: 40px

.signup-verification__controls .btn
  padding: 12px 76px
  @include ss
    width: 100%
</style>
